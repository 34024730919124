//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-980:_524,_4888,_1164,_9568,_8356,_4432,_2332,_1267;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-980')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-980', "_524,_4888,_1164,_9568,_8356,_4432,_2332,_1267");
        }
      }catch (ex) {
        console.error(ex);
      }