const baseFitmentFields = ['Year', 'Make', 'Model', 'ExtraPanelMake'];
const extraFitmentFields = ['Series/Class', 'Chassis'];

const vehicleTabsConfig = [
  {
    title: 'Year/Make/Model',
    fields: {
      base: ['Year', 'Make', 'Model'],
      extra: [],
    },
  },
  {
    title: 'Series/Chassis',
    fields: {
      base: ['ExtraPanelMake'],
      extra: ['Series/Class', 'Chassis'],
    },
  },
];

export default {
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 30,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm-results',
      template: 'SearchPage',
    },
    {
      name: 'CategorySearchPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'Garage',
      location: { insertAfter: '.header_cart', class: 'header_cart' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '.header_search', class: 'cm_search-box-root_container' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'price': 'Price: low to high',
        'price:desc': 'Price: high to low',
        'published_at:desc': 'Date: Newest to Oldest',
        'published_at': 'Date: Oldest to Newest',
        'title': 'Name',
        'on_sale:desc': 'On Sale',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'FilterChips',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'HomeVehicleWidget_tabs',
      type: 'VehicleTabs',
      location: {
        insertBefore: 'body.template-index #after_slider_container',
        class: 'container cm_vehicle-widget__home',
      },
      template: 'fitmentSearch/vehicleTabs',
      tabs: vehicleTabsConfig,
    },
    {
      name: 'VehicleTabs',
      columnBreakpoint: 770,
      tabs: vehicleTabsConfig,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
      columnBreakpoint: 450,
    },
    {
      name: 'facet-dialog',
      type: 'ContextDialog',
      template: 'Dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'DialogVehicleWidget_tabs',
      type: 'VehicleTabs',
      tabs: vehicleTabsConfig,
    },
    {
      name: 'VehicleWidgetDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
