//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-980:_5148,_1700,_3480,_3652,_4508,_4556,_9068,_4568;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-980')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-980', "_5148,_1700,_3480,_3652,_4508,_4556,_9068,_4568");
        }
      }catch (ex) {
        console.error(ex);
      }